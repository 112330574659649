
































































































import {Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import {DataTableHeader} from 'vuetify';
import IPaymentMethod from '@/types/IPaymentMethod';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import PaymentService from '@/services/PaymentService';
import PaymentMethodModal from '@/components/Subscription/PaymentMethodModal.vue'

@Component({
  name: 'PaymentMethodsTable',
  components: {
    PaymentMethodModal,
  },
})
export default class PaymentMethodsTable extends Vue {
  items: IPaymentMethod[] = [];
  headers: DataTableHeader[] = [
    {
      value: 'cardType',
      width: '52px',
      sortable: false,
      text: ''
    },
    {
      value: 'cardInfo',
      sortable: false,
      text: ''
    },
    {
      value: 'options',
      width: '70px',
      sortable: false,
      text: ''
    }
  ]

  menuCancel: boolean = false;
  loading: boolean = false
  removingMethodId: number | null = null
  makingMethodDefaultId: number | null = null

  mounted() {
    this.fetchData();
  }

  async fetchData() {
    this.loading = true
    try {
      this.items = await PaymentService.getPaymentMethods()
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false
    }
  }

  async addPaymentMethod(val:Object) {
    this.loading = true;
    try {
      await PaymentService.addPaymentMethodAsDefault(val)
      notify(NotificationTypes.success, this.$t('SYSTEM_TODO'));
      await this.fetchData();
    } catch (e) {
      resolveError(e, 'todo');
    } finally {
      this.loading = false;
    }
  }

  async makePaymentMethodDefault(item: IPaymentMethod) {
    this.makingMethodDefaultId = item.id
    try {
      await PaymentService.setPaymentMethodAsDefault(item.id)
      notify(NotificationTypes.success, this.$t('SYSTEM_CHANGED_DEFAULT_PAYMENT_METHOD'));
      await this.fetchData();
    } catch (e) {
      resolveError(e, 'make_payment_method_default');
    } finally {
      this.makingMethodDefaultId = null
    }
  }

  async removePaymentMethod(item: IPaymentMethod) {
    this.removingMethodId = item.id
    try {
      await PaymentService.removePaymentMethod(item.id)
      notify(NotificationTypes.success, this.$t('SYSTEM_REMOVED_PAYMENT_METHOD'));
      this.menuCancel = false;
      await this.fetchData();
    } catch (e) {
      resolveError(e, 'remove_payment_method');
    } finally {
      this.removingMethodId = null
    }
  }
}
