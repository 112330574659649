



















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import TranslationService from '@/services/TranslationService';
import IPeriod from '@/types/IPeriod';

interface StripeStyles {
  classes: {
    [key: string]: string
  },
  styles: {
    [key: string]: {
      [key: string]: string
    }
  }
}

@Component({name: 'StripePayment'})

export default class StripePayment extends Vue {

  @Prop()
  period!: IPeriod;

  @Prop()
  activeSubscription!: object;

  dialog: boolean = false;
  openCouponInput = false;
  rebateCouponValue: string | null = null;
  error: any = null;
  language: string | undefined = 'en';
  loading: boolean = false;
  private stripe: any = null;
  private stripeElements: any = null;
  private card: any = null;

  get stripeStyles(): StripeStyles {
    return {
      classes: {
        base: 'stripe__input',
      },
      styles: {
        base: {
          color: 'black',
        },
        invalid: {
          color: 'red',
        },
      },
    };
  }

  @Watch('dialog')
  onOpenModal(newVal: boolean) {
    if (newVal) {
      setTimeout(() => {
        this.initStripeCheckout();
      });
    } else {
      this.stripe = null;
      this.stripeElements = null;
      this.rebateCouponValue = null;
      this.openCouponInput = false;
      this.card = null;
      this.error = null;
    }
  }

  async mounted() {
    const language = await TranslationService.getCurrentLanguage();
    this.language = language && language.shortcut ? language.shortcut.toLowerCase() : 'en';
  }

  async onCheckout() {
    this.loading = true;
    const {
      token,
      error,
    } = await this.stripe.createToken(this.card);
    if (!error) {

    }
    this.loading = false;
  }

  toggleVisibility() {
    this.dialog = false;
  }

  private initStripeCheckout() {
    if (window) {
      // @ts-ignore
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, {locale: this.language});
      this.stripeElements = this.stripe.elements();
      this.card = this.stripeElements.create('card', {
        classes: this.stripeStyles.classes,
        style: this.stripeStyles.styles,
      });
      this.card.mount('#card-element');
      this.onCheckoutFormChange();
    }
  }

  private onCheckoutFormChange() {
    this.card.addEventListener('change', ({error}: any) => {
      const displayError = document.getElementById('card-errors');
      if (displayError) {
        if (error) {
          this.error = error.message;
          displayError.classList.add('card-errors');
          displayError.textContent = error.message;
        } else {
          displayError.classList.remove('card-errors');
          displayError.textContent = '';
          this.error = null;
        }
      }
    });
  }
}
