















































































import {Component, Emit, Vue} from 'vue-property-decorator';
import DatePicker from '@/components/DatePicker.vue'
import {required} from '@/utils/validationRules';

@Component({
    name: 'PaymentMethodModal',
    components: {
        DatePicker
    }
})


export default class PaymentMethodsTable extends Vue{
    form = {
        cvv: '',
        expirationDate: '',
        number: ''
    }

    rules = {
        required
    }

    dialog:Boolean = false

    @Emit('onSave')
    save() {
        this.dialog = false
        return this.form
    }
}
