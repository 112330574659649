





























































































































































import PaymentHistory from '@/components/Subscription/PaymentHistory.vue';
import {Component, Vue} from 'vue-property-decorator';
import {IPaymentList} from '@/types/IPaymentList';
import UserFactory from '../factories/UserFactory';
import {IPagination} from '@/types/IPagination';
import DataTableFactory from '../factories/DataTableFactory';
import PaymentService from '../services/PaymentService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import IPlan from '@/types/IPlan';
import StripePayment from '@/components/Subscription/StripePayment.vue';
import PaymentMethodsTable from '@/components/Subscription/PaymentMethodsTable.vue';
import PageWrap from '@/components/PageWrap.vue';

@Component({
  name: 'Subscription',
  components: {
    PageWrap,
    PaymentMethodsTable,
    PaymentHistory,
    StripePayment,
  },
})

export default class Company extends Vue {
  paymentList: IPaymentList = UserFactory.toPaymentsList({});
  paymentsParams: IPagination = DataTableFactory.toPagination({});
  subscribePlanList: IPlan[] = [];
  loading: Boolean = false;
  activeSubscription: any = {};
  menuCancel: boolean = false;
  menuRestore: boolean = false;

  created() {
    this.fetchPayments();
    this.fetchSubscriptionPlans();
    this.getActiveSubscription();
  }

  mounted() {
    PaymentService.attachStripeScript();
  }

  async fetchPayments() {
    try {
      this.paymentList = this.parsePaymentsData(await PaymentService.getPayments(this.paymentsParams));
      this.paymentsParams.totalElements = this.paymentList.totalElements;
    } catch (e) {
      resolveError(e, 'fetch_data');
    }
  }

  async getActiveSubscription() {
    try {
      this.activeSubscription = await PaymentService.getActiveSubscription();
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  async fetchSubscriptionPlans(): Promise<void> {
    this.loading = true;
    try {
      this.subscribePlanList = await PaymentService.fetchPlans();
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  async cancelSubscription() {
    this.loading = true;
    try {
      await PaymentService.cancelSubscription();
      notify(NotificationTypes.success, this.$t('SYSTEM_SUBSCRIPTION_CANCELED'));
      await this.getActiveSubscription();
      this.$nextTick(() => {
        window.location.reload();
      });
      this.menuCancel = false;
    } catch (e) {
      resolveError(e, 'fetch_data');
      this.loading = false;
    }
  }

  async restoreSubscription() {
    this.loading = true;
    try {
      await PaymentService.restoreSubscription();
      notify(NotificationTypes.success, this.$t('SYSTEM_SUBSCRIPTION_RESTORED'));
      await this.getActiveSubscription();
      this.$nextTick(() => {
        window.location.reload();
      });
      this.menuCancel = false;
    } catch (e) {
      resolveError(e, 'fetch_data');
      this.loading = false;
    }
  }

  async buySubscription() {
    try {
      await PaymentService.checkoutSubscription(this.subscribePlanList[0].periods[0].planPeriodId);
      notify(NotificationTypes.success, this.$t('SYSTEM_PAYMENT_SUCCESSFUL'));
      await this.getActiveSubscription();
      this.$nextTick(() => {
        window.location.reload();
      });
    } catch (e) {
      resolveError(e, 'payment');
    } finally {
      this.loading = false;
    }
  }

  parsePaymentsData(payments: IPaymentList) {
    payments.content.forEach((element) => {
      element.loading = false;
    });

    return payments;
  }

  paymentsPaginationChanged(pagination: IPagination) {
    if (this.paymentsParams.page === pagination.page && this.paymentsParams.size === pagination.itemsPerPage) {
      return;
    }
    this.paymentsParams.page = pagination.page || 1;
    this.paymentsParams.size = pagination.itemsPerPage || 10;

    this.fetchPayments();
  }
};
